// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fuec-primary: mat.define-palette(mat.$indigo-palette);
$fuec-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fuec-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fuec-theme: mat.define-light-theme(
    (
        color: (
            primary: $fuec-primary,
            accent: $fuec-accent,
            warn: $fuec-warn
        )
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fuec-theme);

/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

//@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
//@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
//@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

//@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
//@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
//@import '~admin-lte/dist/css/adminlte.min.css';
@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:wght@300&display=swap');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import '~ngx-toastr/toastr';

@import './variables';

body {
    width: 100vw;
    height: 100vh;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

$breakpoint-latop-1024: 1024px;
$breakpoint-iPad: 768px;
$breakpoint-mobile-big: 600px;
$breakpoint-mobile-small: 500px;

h2.title-main {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    /* identical to box height */
    color: #8c8c8c;
}

.content-wrapper {
    background-color: #ffffff !important;
    padding: 1rem;
    padding-bottom: 5rem;
}

.cdk-overlay-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    .cdk-overlay-connected-position-bounding-box {
        .cdk-overlay-pane.mat-tooltip-panel-below.mat-tooltip-panel {
            position: absolute !important;
            font-size: 0.65rem;
            font-family: 'Montserrat';
            font-weight: 600;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.cdk-visually-hidden {
    position: fixed;
}
