// Colors
$white: #ffffff;
$black: rgba(0, 0, 0, 0.87);
$muted: var(--text-muted);

$primary: var(--primary);
$secondary: var(--secondary);
$error: var(--error);
$brand: $primary;

$bg-default: var(--bg-default);
$bg-paper: var(--bg-paper);

$text-body: var(--text-body);
$text-muted: var(--text-muted);
$text-disabled: var(--text-disabled);
$text-hint: var(--text-hint);
$light-gray: rgba(0, 0, 0, 0.08);

// Layout
$topbar-mobile-width: 220px;
$topbar-height: 64px;
$sidenav-width: 260px;
$sidenav-button-width: 220px;
$sidenav-compact-width: 48px;
$contained-layout-width: 1200px;

$breakpoint-latop-1024: 1024px;
$breakpoint-iPad: 768px;
$breakpoint-mobile-big: 600px;
$breakpoint-mobile-small: 500px;

//Fonts Family
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
$Montserrat: "Montserrat", sans-serif;
$Ubuntu: "Ubuntu", sans-serif;

// Typography
$font: var(--font);
$font-h1: var(--font-h1);
$font-h2: var(--font-h2);
$font-h3: var(--font-h3);
$font-h4: var(--font-h4);
$font-h5: var(--font-h5);
$font-h6: var(--font-h6);
$font-caption: var(--font-caption);
$font-overline: var(--font-overline);
$font-button: var(--font-button);
$font-body-1: var(--font-body-1);
$font-body-2: var(--font-body-2);
$font-subtitle-1: var(--font-subtitle-1);
$font-subtitle-2: var(--font-subtitle-2);
$font-heading: var(--font-heading);
$font-title: var(--font-title);
$font-display-1: var(--font-display-1);
$font-display-2: var(--font-display-2);
$font-display-3: var(--font-display-3);
$font-display-4: var(--font-display-4);

// box shadow
$elevation-z0: var(--elevation-z0);
$elevation-z1: var(--elevation-z1);
$elevation-z2: var(--elevation-z2);
$elevation-z3: var(--elevation-z3);
$elevation-z4: var(--elevation-z4);
$elevation-z5: var(--elevation-z5);
$elevation-z6: var(--elevation-z6);
$elevation-z7: var(--elevation-z7);
$elevation-z8: var(--elevation-z8);
$elevation-z9: var(--elevation-z9);
$elevation-z10: var(--elevation-z10);
$elevation-z11: var(--elevation-z11);
$elevation-z12: var(--elevation-z12);
$elevation-z13: var(--elevation-z13);
$elevation-z14: var(--elevation-z14);
$elevation-z15: var(--elevation-z15);
$elevation-z16: var(--elevation-z16);
$elevation-z17: var(--elevation-z17);
$elevation-z18: var(--elevation-z18);
$elevation-z19: var(--elevation-z19);
$elevation-z20: var(--elevation-z20);
$elevation-z21: var(--elevation-z21);
$elevation-z22: var(--elevation-z22);
$elevation-z23: var(--elevation-z23);
$elevation-z24: var(--elevation-z24);

/* Colores Primarios */

$blue1: #00249c; /*AZÚL*/
.blue1 {
  color: #00249c;
  fill: #00249c;
}

$blue2: #0047bb; /*AZÚL*/
.blue2 {
  color: #0047bb;
  fill: #0047bb;
}

$red1: #c6007e; /*MAGENTA*/
.red1 {
  color: #c6007e;
  fill: #c6007e;
}

/* Colores Destacados*/

$sky1: #01bebd; /*CIELO*/
.sky1 {
  color: #01bebd;
  fill: #01bebd;
}

$red2: #d4409e; /*MAGENTA 75*/
.red2 {
  color: #d4409e;
  fill: #d4409e;
}

/* Colores Neutros */

$gray1: #8c8c8c; /*GRIS*/
.gray1 {
  color: #8c8c8c;
  fill: #8c8c8c;
}

$gray2: #a8a8a8; /*GRIS 75*/
.gray2 {
  color: #a8a8a8;
  fill: #a8a8a8;
}

$gray3: #c5c5c5; /*GRIS 50*/
.gray3 {
  color: #c5c5c5;
  fill: #c5c5c5;
}

/* Colores Claros */

$sky2: #40cee4; /*CIELO 75*/
.sky2 {
  color: #40cee4;
  fill: #40cee4;
}

$red3: #e280be; /*MAGENTA 50*/
.red3 {
  color: #e280be;
  fill: #e280be;
}

$gray4: #e2e2e2; /*GRIS 25*/
.gray4 {
  color: #e2e2e2;
  fill: #e2e2e2;
}

/* Otros Colores */

$blue80: #00249c80; /*AZÚL 80 T*/
.blue80 {
  color: #00249c80;
}

$blue60: #00249c60; /*AZÚL 60 T*/
.blue60 {
  color: #00249c60;
}

$blue40: #00249c40; /*AZÚL 40 T*/
.blue40 {
  color: #00249c40;
}

$blue20: #00249c20; /*AZÚL 20 T*/
.blue20 {
  color: #00249c20;
}

$gray80: #8c8c8c80; /*GRIS 80 T*/
.gray80 {
  color: #8c8c8c80;
}

$gray60: #8c8c8c60; /*GRIS 60 T*/
.gray60 {
  color: #8c8c8c60;
}

$gray40: #8c8c8c40; /*GRIS 40 T*/
.gray40 {
  color: #8c8c8c40;
}

$gray20: #8c8c8c20; /*GRIS 20 T*/
.gray20 {
  color: #8c8c8c20;
}

$white: white; /*BLANCO*/
.white {
  color: white;
}

$white95: #ffffff95; /*BLANCO 95 T*/
.white95 {
  color: #ffffff95;
}

$white90: #ffffff90; /*BLANCO 90 T*/
.white90 {
  color: #ffffff90;
}

$white80: #ffffff80; /*BLANCO 80 T*/
.white80 {
  color: #ffffff80;
}

$white70: #ffffff70; /*BLANCO 70 T*/
.white70 {
  color: #ffffff70;
}

$white60: #ffffff60; /*BLANCO 60 T*/
.white60 {
  color: #ffffff60;
}

$white50: #ffffff50; /*BLANCO 50 T*/
.white60 {
  color: #ffffff50;
}

$white40: #ffffff40; /*BLANCO 40 T*/
.white40 {
  color: #ffffff40;
}

$white30: #ffffff30; /*BLANCO 30 T*/
.white30 {
  color: #ffffff30;
}

$white20: #ffffff20; /*BLANCO 20 T*/
.white20 {
  color: #ffffff20;
}

$white10: #ffffff10; /*BLANCO 10 T*/
.white10 {
  color: #ffffff10;
}

$transparente: #ffffff00; /*TRANSPARENTE*/
.transparente {
  color: #ffffff00;
}
